import React from 'react';
import enewsLogo from '@/images/newsletter-enews-podcasts.png';
import dailyPopIcon from '@/images/newsletter-podcast-dailypop.jpg';
import nightlyPopIcon from '@/images/newsletter-podcast-nightlypop.jpg';
import sipIcon from '@/images/newsletter-podcast-sip.jpg';
import WidgetHeader from '@/components/widgets/WidgetHeader';
import './Listen.scss';

const podcastData = [
	{
		title: 'Daily Pop',
		src: dailyPopIcon,
		url: 'linktr.ee/dailypop'
	},
	{
		title: 'Just The SIP',
		src: sipIcon,
		url: 'linktr.ee/justthesip'
	},
	{
		title: 'Nightly Pop',
		src: nightlyPopIcon,
		url: 'linktr.ee/nightlypop'
	}
];

/**
 * @function Listen
 * @description Listen seciton of the newsletter page
 * @returns {React.ReactElement}
 */
const Listen = () => {
	return (
		<section className="listen newsletter__section">
			<div className="container">
				<WidgetHeader title="listen" />
				<div className="listen__content">
					<img alt="enews podcasts" src={enewsLogo} className="listen__logo" />
					<p>Listen to your favorites and discover new shows</p>
					<div className="listen__link-section">
						{podcastData.map(({ title, src, url }, index) => {
							return (
								<div key={index} className="listen__button">
									<a
										rel="noopener noreferrer"
										target="_blank"
										href={`https://${url}`}
									>
										<img alt={`${title} Podcast Icon`} src={src} />
									</a>
									<h6>{title}</h6>
								</div>
							);
						})}
					</div>
				</div>
			</div>
		</section>
	);
};

Listen.displayName = 'Listen';

export default Listen;
