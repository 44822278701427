import React, { useContext, useEffect, useRef, useState } from 'react';
import WidgetHeader from '@/components/widgets/WidgetHeader';
import alertsLogo from '@/images/newsletter-enews-alerts.png';
import icon from '@/images/newsletter-notifications-icon.png';
import { LINK_LOCATION } from './newsletterAnalytics';
import { PageContext } from '@/components/layout/page/PageContext';
import airshipInit from '@/components/airship/airshipInit';
import './Notifications.scss';

/**
 * @function Notifications
 * @description Notifications seciton of the newsletter page
 * @description This section will be visible only on desktop
 * @returns {React.ReactElement}
 */
const Notifications = () => {
	const { edition } = useContext(PageContext);
	const [canRegister, setCanRegister] = useState(true);
	const register = useRef(null);

	useEffect(() => {
		if (typeof window !== 'undefined') {
			// load Airship scripts at page load
			// can't defer loading the scripts or else the browser won't register the event as a user generated click, which blocks the prompt in browsers such as Safari
			if (typeof UA === 'undefined') {
				airshipInit();
			}
			// eslint-disable-next-line no-undef
			UA.then(function (sdk) {
				if (
					// browser doesn't support native notifications
					!sdk.isSupported ||
					// browser is blocked
					!sdk.canRegister
				) {
					// then hide this section
					setCanRegister(false);
				} else {
					register.current = () => {
						const registerPromise = sdk.register();
						registerPromise
							.then(function () {
								// user has opted into to notifications
								// set users content edition
								sdk.channel.tags.add(edition, 'edition');
							})
							.catch(function (err) {
								// error logs if user blocks or closes out browser prompt
								console.log('Airship error: ' + err);
							});
					};
				}
			}).catch(function (err) {
				setCanRegister(false);
				console.log(err);
			});
		}
	}, []);

	return (
		<section
			className={`notifications newsletter__section is-hidden-touch ${
				!canRegister ? 'is-hidden' : ''
			}`}
		>
			<div className="container">
				<WidgetHeader title="notifications" />
				<div className="notifications__content">
					<div className="notifications__content-wrap">
						<img className="notifications__logo" alt="enews alerts" src={alertsLogo} />
						<img className="notifications__icon" alt="notifications icon" src={icon} />
						<h4>Want the Latest Breaking Entertainment News?</h4>
						<button
							onClick={() => {
								register.current();
							}}
							className="button"
							data-analytics-linkname="enable-browser-notifications"
							data-analytics-linklocation={LINK_LOCATION}
						>
							enable browser notifications
						</button>
					</div>
				</div>
			</div>
		</section>
	);
};

Notifications.displayName = 'Notifications';

export default Notifications;
