import React, { useState, Fragment, useContext } from 'react';
import { PageContext } from '@/components/layout/page/PageContext';
import { useInView } from 'react-intersection-observer';
import { useFormik } from 'formik';
import { array, func, bool } from 'prop-types';
import { object, string } from 'yup';
import { newsletterSubscribe } from '@/api/newsletterApi';
import { LINK_LOCATION } from './newsletterAnalytics';
import ChevronSvg from '@/images/chevron.svg';
import SvgImage from '@/components/icons/SvgImage';
import closeIconSvg from '@/images/close-icon.svg';
import { useParamToggleContext } from '@/components/param-toggle/ParamToggleContext';
import ReCAPTCHA from 'react-google-recaptcha';
import './SubscribeForm.scss';

/**
 * @function SubscribeForm
 * @description renders the subscription form in the top section of the newsletter page
 * @param {Object} props
 * @param {Array} props.selectedTiles - an array of selected newsletters
 * @param {Function} props.setSelected - function used to reset selections after form is subitted
 * @param {Boolean} [props.captchaEnabled=true] - determines if recaptcha is enabled
 * @returns {React.ReactElement}
 */
const SubscribeForm = ({ selectedTiles, setSelected, captchaEnabled }) => {
	const { THIRD_PARTY_ENABLED } = useParamToggleContext();
	const [emailTyped, setEmailTyped] = useState(false);
	const [success, setSuccess] = useState(false);
	const [apiError, setApiError] = useState('');
	const [shouldDock, setShouldDock] = useState(true);
	const { source } = useContext(PageContext);

	const [ref, inView] = useInView({
		threshold: 0
	});

	const formik = useFormik({
		initialValues: {
			email: ''
		},
		validationSchema: object().shape({
			email: string()
				.email('Please enter a valid email')
				.required('Please enter a valid email')
		}),
		onSubmit: (values, { setSubmitting }) => {
			newsletterSubscribe({
				email: values.email,
				segments: selectedTiles,
				source,
				recaptchaVal
			})
				.then((success) => {
					setSubmitting(false);
					setSuccess(true);
					onChange(0);
				})
				.catch((err) => {
					setSubmitting(false);
					if (err) setApiError("Oops! We've encountered an error. Please try again.");
				});
		}
	});

	const {
		handleSubmit,
		handleChange,
		values,
		isSubmitting,
		isValid,
		dirty,
		touched,
		errors,
		setTouched
	} = formik;

	const resetForm = () => {
		setSuccess(false);
		setSelected([]);
	};

	// recaptcha stuff
	const [showRecaptcha, setShowRecaptcha] = useState(false);
	const [recaptchaVal, setRecaptchaVal] = useState(0);
	const recaptchaRef = React.createRef();
	const onChange = (value) => {
		setRecaptchaVal(value);
		setShowRecaptcha(!value);
	};

	if (!selectedTiles || !setSelected) return null;
	const hasSelection = selectedTiles.length;

	const emailAnalytics = () => {
		if (!emailTyped) return {};
		return {
			'data-analytics-linkname': 'newsletter:signup:start',
			'data-analytics-linklocation': 'follow-us-page'
		};
	};

	return (
		<Fragment>
			<div
				className={`container subscribe-form ${
					shouldDock ? 'subscribe-form--dockable' : ''
				} ${success ? 'subscribe-form--success' : ''} ${
					!inView && shouldDock ? 'subscribe-form--docked' : ''
				}`}
				data-hook="subscribe-form"
			>
				<div className="subscribe-form__close-wrap">
					{!inView && shouldDock && (
						<div
							className="subscribe-form__close"
							data-hook="close-overlay-link"
							onClick={() => {
								setShouldDock(false);
							}}
							onKeyPress={() => {
								setShouldDock(false);
							}}
							role="button"
							tabIndex="0"
						>
							<SvgImage image={closeIconSvg} />
						</div>
					)}
				</div>
				{success ? (
					<div
						data-analytics-linkname="newsletter:signup:complete"
						data-analytics-linklocation={LINK_LOCATION}
						className="subscribe-form__success"
					>
						<h4>Thanks for Subscribing</h4>
						<div
							tabIndex="0"
							role="button"
							onClick={resetForm}
							onKeyPress={resetForm}
							className="subscribe-form__more"
						>
							subscribe to more newsletters
							<SvgImage className="subscribe-form__chevron" image={ChevronSvg} />
						</div>
					</div>
				) : (
					<div className="is-flex  is-justify-content-center">
						<div className="subscribe-form__content">
							<div className="subscribe-form__fields-wrapper">
								{(touched.email && errors.email && hasSelection && (
									<div className="help">{errors.email}</div>
								)) ||
									null}
								{(apiError && hasSelection && (
									<div
										data-analytics-linkname="newsletter:signup:error"
										data-analytics-linklocation={LINK_LOCATION}
										className="help"
									>
										{apiError}
									</div>
								)) ||
									null}
								{(!hasSelection && (
									<div className="help">Please select a newsletter.</div>
								)) ||
									(isValid && emailTyped && !recaptchaVal && (
										<div className="help">Please complete ReCAPTCHA.</div>
									)) ||
									null}
								<form>
									<input
										placeholder="* Enter your email"
										required
										type="email"
										name="email"
										{...emailAnalytics()}
										onChange={handleChange}
										onBlur={() => setTouched({ ...touched, email: true })}
										onFocus={() => {
											setApiError('');
											if (!recaptchaVal) setShowRecaptcha(true);
										}}
										onKeyPressCapture={() => !emailTyped && setEmailTyped(true)}
										value={values.email}
										className="input"
										data-hook="subscribe-form__input"
									/>

									<button
										onClick={handleSubmit}
										onKeyPress={handleSubmit}
										type="submit"
										className={`button ${isSubmitting ? 'is-loading' : ''}`}
										disabled={
											!dirty ||
											!isValid ||
											!hasSelection ||
											(captchaEnabled && !recaptchaVal)
										}
										data-hook="subscribe-form__submit-button"
									>
										subscribe
									</button>

									<div className="subscribe-form__terms">
										By subscribing to our newsletter you agree to the{' '}
										<a
											rel="noopener noreferrer"
											target="_blank"
											href="https://www.nbcuniversal.com/privacy/"
										>
											Privacy Policy
										</a>{' '}
										and{' '}
										<a
											rel="noopener noreferrer"
											target="_blank"
											href="https://www.nbcuniversal.com/terms"
										>
											{' '}
											Terms of Use
										</a>
									</div>

									{captchaEnabled && (
										<div
											className={`subscribe-form__recaptcha ${
												!showRecaptcha
													? 'subscribe-form__recaptcha--hidden'
													: ''
											}`}
										>
											<ReCAPTCHA
												ref={recaptchaRef}
												sitekey="6LcubwMTAAAAAO_GOLKxKVvbCudISSGIDKdblDau"
												onChange={onChange}
											/>
										</div>
									)}
								</form>
							</div>
						</div>
					</div>
				)}
			</div>
			{/*
					hack: 0px div used to detect when the bottom of the form will appear
					used to trigger the hiding of the close button
				 */}
			<div className="subscribe-form__end" ref={ref}></div>
		</Fragment>
	);
};

SubscribeForm.defaultProps = {
	captchaEnabled: true
};

SubscribeForm.propTypes = {
	selectedTiles: array.isRequired,
	setSelected: func.isRequired,
	captchaEnabled: bool
};

SubscribeForm.displayName = 'SubscribeForm';

export default SubscribeForm;
