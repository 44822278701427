import React from 'react';
import WidgetHeader from '@/components/widgets/WidgetHeader';
import SvgImage from '@/components/icons/SvgImage';
import tiktokSvg from '@/images/tik-tok.svg';
import fbSvg from '@/images/facebook.svg';
import xSvg from '@/images/x-icon.svg';
import instagramSvg from '@/images/instagram.svg';
import snapchatSvg from '@/images/snapchat.svg';
import youtubeSvg from '@/images/youtube.svg';
import { LINK_LOCATION } from './newsletterAnalytics';
import './Follow.scss';

export const socialLinks = [
	{
		image: tiktokSvg,
		link: 'https://www.tiktok.com/@enews',
		name: 'tiktok',
		linkname: 'tiktok-follow-icon'
	},
	{
		image: fbSvg,
		link: 'http://www.facebook.com/enews',
		name: 'fb',
		linkname: 'facebook-follow-icon'
	},
	{
		image: xSvg,
		link: 'http://www.twitter.com/intent/follow?screen_name=enews',
		name: 'x',
		linkname: 'x-follow-icon'
	},
	{
		image: instagramSvg,
		link: 'http://www.instagram.com/enews',
		name: 'instagram',
		linkname: 'instagram-follow-icon'
	},
	{
		image: snapchatSvg,
		link: 'http://www.snapchat.com/add/e_news',
		name: 'snapchat',
		linkname: 'snapchat-follow-icon'
	},
	{
		image: youtubeSvg,
		link: 'http://www.youtube.com/user/enews',
		name: 'youtube',
		linkname: 'youtube-follow-icon'
	}
];

/**
 * @function Follow
 * @description follow seciton of the newsletter page
 * @returns {React.ReactElement}
 */
const Follow = () => {
	return (
		<section className="follow newsletter__section">
			<div className="container">
				<WidgetHeader title="follow" />
				<h4 className="follow__title">Join Our 40+ Million Followers</h4>
				<div className="follow__content">
					{socialLinks.map(({ image, link, name, linkname }, index) => (
						<a
							data-analytics-linklocation={LINK_LOCATION}
							data-analytics-linkname={linkname}
							key={`sociallink-${index}`}
							id={`${name}-icon`}
							className="follow__icon-wrap"
							href={link}
						>
							<SvgImage className="follow__icon" image={image} />
						</a>
					))}
				</div>
			</div>
		</section>
	);
};

Follow.displayName = 'Follow';

export default Follow;
