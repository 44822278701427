/**
 * @function airshipInit
 * @description generated snippet from Airship
 */
export default () => {
	/* eslint-disable */
	// 86acbd31cd7c09cf30acb66d2fbedc91daa48b86:1706554957.2439435
	!function(n,r,e,t,c){var i,o="Promise"in n,u={then:function(){return u},catch:function(n){
	return n(new Error("Airship SDK Error: Unsupported browser")),u}},s=o?new Promise((function(n,r){i=function(e,t){e?r(e):n(t)}})):u
	;s._async_setup=function(n){if(o)try{i(null,n(c))}catch(n){i(n)}},n[t]=s;var a=r.createElement("script");a.src=e,a.async=!0,a.id="_uasdk",
	a.rel=t,r.head.appendChild(a)}(window,document,'https://aswpsdkus.com/notify/v1/ua-sdk.min.js',
		'UA', {
			vapidPublicKey: 'BMH0FF9WOXcp5YWrmnsNxBaOtiO-uDsUEHkbRmIEkkpIAcCtP3ieIeeRLz6azz2P77mGVRHAT36HM7rCJ_5zN9A=',
			websitePushId: 'web.com.eonline.safari',
			appKey: 'M8AnyxszToidL-Gwji-7VA',
			token: 'MTpNOEFueXhzelRvaWRMLUd3amktN1ZBOmVPX2tFOGtGb1hLcFRMcUU4VEtxcHlILVA0N3EtTzJ2QUtpVjY5OTJuTUk'
		});
	/* eslint-enable */
};
