import { NEWSLETTER_POST_API } from '@/config/config';

/**
 * @function newsletterSubscribe
 * @description sends newsletter data to back end
 * @param {String} email - user's email
 * @param {Array} segments - array of selected newsletters
 * @param {String} [source] - comes from params ...?source=twitter
 * @param {String} recaptchaVal - recaptcha validation value
 * @returns {Promise} Promise object of api response
 */
export const newsletterSubscribe = ({ email, segments, source, recaptchaVal }) => {
	if (!email || !segments?.length) return Promise.reject(new Error('Missing Data'));

	const data = JSON.stringify({
		email,
		segments: segments.map((s) => s.toUpperCase()),
		analytics: {
			source: source || 'signup-page'
		}
	});

	const queryParams = `g_recaptcha_response=${recaptchaVal}`;

	const options = {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json'
		},
		body: data
	};

	return fetch(`${NEWSLETTER_POST_API}?${queryParams}`, options).then((response) => {
		if (!response.ok) throw new Error(response.statusText);

		return response;
	});
};
