export const SITE_SECTION = 'follow-us';
export const LINK_LOCATION = 'follow-us';

export const pageViewData = {
	pageInfo: {
		siteSection: SITE_SECTION,
		pageType: 'landing'
	},
	contentInfo: {
		originalUrl: 'www.eonline.com/follow-us',
		contentType: 'news',
		pagewidgetType: 'follow-us'
	},
	sponsoredInfo: {
		advertiser: null
	}
};
