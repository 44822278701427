import React, { useState, useEffect } from 'react';
import TitleHeader from '@/components/title-header/TitleHeader';
import SvgImage from '@/components/icons/SvgImage';
import CheckMark from '@/images/checkmark-blue-circle.svg';
import SubscribeForm from './SubscribeForm';
import './Subscribe.scss';

// tiles.name should match the newsletter name in sailthru
const tiles = [
	{
		name: 'tea',
		title: 'Your Morning Tea',
		caption: 'Start your day with the latest in pop culture and more from E! News'
	},
	{
		name: 'daily',
		title: "Today's Top 5",
		caption: "5 things you need to know from the day's top news"
	},
	{
		name: 'ecomm',
		title: 'E! Insider Shop',
		caption: 'Get updates on the biggest sales and must-have products'
	},
	{
		name: 'kardashians',
		title: 'Kards Katch Up',
		caption: 'Keep up with every Kardashian-Jenner update & more'
	}
];

/**
 * @function Subscribe
 * @description top section of the newsletter page
 * @returns {React.ReactElement}
 */
const Subscribe = () => {
	const [selectedTiles, setSelected] = useState([]);
	const [isAllSelected, setIsAllSelected] = useState(false);

	const toggleTile = (name) => {
		const index = selectedTiles.indexOf(name);
		const inSelected = index > -1;

		(inSelected && selectedTiles.splice(index, 1)) || selectedTiles.push(name);
		setSelected([...selectedTiles]);
	};

	const selectAll = () => {
		const allTiles = tiles.map(({ name }) => name);
		setSelected(allTiles);
	};

	useEffect(() => {
		setIsAllSelected(selectedTiles.length === tiles.length);
	});

	return (
		<section className="subscribe">
			<TitleHeader title="subscribe" styleType="SECONDARY" />
			<div className="container">
				<div className="columns is-flex-direction-column is-vcentered">
					<div className="column">
						<h2>Select the Newsletter you&apos;d like to subscribe to.</h2>
					</div>
					<div className="column">
						<button
							data-hook="subscribe-all-button"
							className="button is-fullwidth"
							onKeyPress={selectAll}
							onClick={selectAll}
							disabled={isAllSelected}
						>
							select all
						</button>
					</div>
				</div>
			</div>
			<div className="container subscribe__tiles">
				<div className="columns">
					{tiles.map(({ name, bgWord, title, caption }, index) => {
						const isActive = selectedTiles.indexOf(name) > -1;

						return (
							<div
								data-hook="subscribe-tile"
								key={index}
								className={`column is-12-tablet is-6-desktop subscribe__tile-wrap ${
									isActive ? 'subscribe__tile-wrap--active' : ''
								}`}
							>
								<div
									data-hook="subscribe-tile-button"
									tabIndex="0"
									role="button"
									onKeyPress={() => toggleTile(name)}
									onClick={() => toggleTile(name)}
									className={`subscribe__tile ${
										isActive ? 'subscribe__tile--active' : ''
									}`}
								>
									<div className="subscribe__checkmark">
										<SvgImage image={CheckMark} />
									</div>
									<div className="subscribe__img-wrap">
										{bgWord && <span>{bgWord}</span>}
										<div
											className={`subscribe__img subscribe__img-${name}`}
										></div>
									</div>
									<div className="subscribe__body">
										<h3>{title}</h3>
										<p>{caption}</p>
									</div>
								</div>
							</div>
						);
					})}
				</div>
			</div>
			<SubscribeForm selectedTiles={selectedTiles} setSelected={setSelected} />
		</section>
	);
};

Subscribe.displayName = 'Subscribe';

export default Subscribe;
