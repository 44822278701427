import React from 'react';
import WidgetHeader from '@/components/widgets/WidgetHeader';
import enewsImg from '@/images/newsletter-rss-enews.jpg';
import videoImg from '@/images/newsletter-rss-video.jpg';
import celebrityPhotos from '@/images/newsletter-rss-celebrity-photos.jpg';
import tvScoop from '@/images/newsletter-rss-tv-scoop.jpg';
import styleImg from '@/images/newsletter-rss-style.jpg';
import redCarpet from '@/images/newsletter-rss-red-carpet.jpg';
import { LINK_LOCATION } from './newsletterAnalytics';
import './RssFeed.scss';

const feedsHost = 'https://eol-feeds.eonline.com/';
const feedsList = [
	{
		name: 'E! News',
		url: 'rssfeed/us/top_stories',
		img: enewsImg,
		linkname: 'rss-feed-Enews'
	},
	{
		name: 'Video',
		url: 'video/us/top_stories',
		img: videoImg,
		linkname: 'rss-feed-video'
	},
	{
		name: 'Celebrity Photos',
		url: 'rssfeed/us/photos/big-picture',
		img: celebrityPhotos,
		linkname: 'rss-feed-tvscoop'
	},
	{
		name: 'TV Scoop',
		url: 'rssfeed/us/tv',
		img: tvScoop,
		linkname: 'rss-feed-style'
	},
	{
		name: 'Style',
		url: 'rssfeed/us/style',
		img: styleImg,
		linkname: 'rss-feed-celebrityphotos'
	},
	{
		name: 'Red Carpet',
		url: 'rssfeed/us/red_carpet',
		img: redCarpet,
		linkname: 'rss-feed-redcarpet'
	}
];

/**
 * @function RssFeed
 * @description RssFeed seciton of the newsletter page
 * @returns {React.ReactElement}
 */
const RssFeed = () => {
	return (
		<section className="rss-feed newsletter__section is-hidden-touch">
			<div className="container">
				<WidgetHeader title="rss feed" />
				<div className="container rss-feed__content">
					<div className="columns">
						{feedsList.map(({ name, url, img, linkname }, index) => {
							return (
								<div
									key={index}
									className="column is-12-mobile is-6-tablet is-4-desktop"
								>
									<a
										data-analytics-linkname={linkname}
										data-analytics-linklocation={LINK_LOCATION}
										href={feedsHost + url}
										className="rss-feed__tile"
									>
										<img alt={name} src={img} />
										<h3>{name}</h3>
									</a>
								</div>
							);
						})}
					</div>
				</div>
			</div>
		</section>
	);
};

RssFeed.displayName = 'RssFeed';

export default RssFeed;
