import React, { Fragment } from 'react';
import DataLayer from '@/components/analytics/DataLayer';
import Subscribe from './Subscribe';
import Phone from './Phone';
import Listen from './Listen';
import Follow from './Follow';
import Notifications from './Notifications';
import RssFeed from './RssFeed';
import { Helmet } from 'react-helmet-async';
import { pageViewData } from './newsletterAnalytics';
import MetaTags from '@/components/meta-tags/MetaTags';
import NielsenStaticDCR from '@/components/nielsen/NielsenStaticDCR';
import './Newsletter.scss';

// static meta tag data
const description =
	'Follow us at E! to stay up to date with celebrities, celeb news, and celebrity gossip. Check out the hottest fashion, photos, movies and TV shows!';
const options = { ogImage: false, ogTags: true };
const title = 'Follow Us';
const ogType = 'website';

const metaTagsData = {
	description,
	options,
	title,
	ogType
};

/**
 * @function Newsletter
 * @description renders newsletter page and child components
 * @returns {React.ReactElement}
 */
const Newsletter = () => {
	return (
		<Fragment>
			<Helmet>
				<link
					href="https://fonts.googleapis.com/css?family=Oswald:700&display=swap"
					rel="stylesheet"
				/>
				<title>{title}</title>
			</Helmet>
			<MetaTags {...metaTagsData} />
			<DataLayer pageData={pageViewData} />
			<NielsenStaticDCR assetId="newsletter" siteSection="newsetter" />
			<div className="newsletter">
				<Subscribe />
				<Phone />
				<Listen />
				<Follow />
				<Notifications />
				<RssFeed />
			</div>
		</Fragment>
	);
};

Newsletter.displayName = 'Newsletter';

export default Newsletter;
