import React from 'react';
import { usePageContext, useFeatureToggleContext } from '@/components/layout/page/PageContext';
import { string, object, array } from 'prop-types';
import { Helmet } from 'react-helmet-async';
import striptags from 'striptags';
import generateResizeLink from '@/utils/generateResizeLink';
import { formatCategories } from '@/components/meta-tags/helpers';
import format from 'date-fns/format';
import { APP_HOSTNAME } from '@/config/config';
import getLocaleString from '@/components/i18n/getLocaleString';

const defaultOptions = {
	twitter: false,
	ogTags: false,
	ogImage: true,
	sailthru: false
};

// facebook wants a 1.91:1 for the og image
const ogImageWidth = 1200;
const ogImageHeight = 630;
const crop = true;

// for feature toggle
const ogImageWidthSquare = 1080;
const ogImageHeightSquare = 1080;

// twitter wants a 2:1
const twitterImgW = 1080;
const twitterImgH = 540;

// default image if no thumb uri provided
const eLogoImg =
	'https://akns-images.eonline.com/eol_images/Entire_Site/2020330/rs_112x112-200430123021-112X112-e-logo.png';

// replaces Helmet's encode character functionality;
// doesn't encode '&' characters so the image generator works as expected
const encodeSpecialCharacters = (str) => {
	if (typeof str !== 'string') return '';

	return str
		.replace(/</g, '&lt;')
		.replace(/>/g, '&gt;')
		.replace(/"/g, '&quot;')
		.replace(/'/g, '&#x27;');
};

/**
 * @function getFullUrl
 * @param {String} path
 * @returns {String}
 */
const getFullUrl = (path) => {
	// remove query params from path
	const simplePath = path.split('?')[0];

	// remove trailing slash and return
	return `https://${APP_HOSTNAME}${simplePath}`.replace(/\/$/, '');
};

/**
 * @function MetaTags
 * @description adds common meta tags to the head of a page
 * @param {Object} props
 * @param {String} [props.id] id of the article, gallery, video
 * @param {String} [props.title] title of article
 * @param {String} [props.publishDate] date article was published
 * @param {String} [props.categories]
 * @param {String} [props.ogType] overrides default ogType
 * @param {String} [props.description] description field in the cms
 * @param {String} [props.thumbnailUri]
 * @param {Object} [props.customNameTags] page specific name type meta tags can be passed here
 * @param {Object} [props.customPropTags] page specific property type meta tags can be passed here
 * @param {Object} [props.options] contains toggles for optional sections
 * @param {Boolean} [props.options.hasPagination] whether it should include page number in meta title
 * @returns {React.ReactElement}
 */
const MetaTags = ({
	id,
	title,
	publishDate,
	categories,
	ogType,
	description,
	thumbnailUri,
	customNameTags,
	customPropTags,
	options
}) => {
	const {
		edition,
		path,
		translations,
		pageNum,
		articleId,
		galleryId,
		videoId
	} = usePageContext();
	const { OG_IMAGE } = useFeatureToggleContext();

	if (!description) return null;

	const locale = getLocaleString(edition);
	const currentPage = parseInt(pageNum) || 1;
	const noTagsTitle = encodeSpecialCharacters(striptags(title));
	const isDetailPage = (articleId && true) || (galleryId && true) || (videoId && true) || false;
	const titleWithSuffix = `${noTagsTitle}${
		options?.hasPagination && currentPage > 1
			? ` - ${translations.PAGE_META} ${currentPage}`
			: ''
	}${isDetailPage ? '' : ` - ${translations?.TITLE_SUFFIX || ''}`}`;
	const noTagsDescription = encodeSpecialCharacters(striptags(description));
	const fullUrl = getFullUrl(path);

	const resizeImg = !!thumbnailUri;

	const omgImageSrcObj =
		resizeImg && generateResizeLink(thumbnailUri, ogImageWidth, ogImageHeight, crop);
	const ogImageUrl = omgImageSrcObj ? omgImageSrcObj.src : eLogoImg;

	const omgImageSrcObjSquare =
		resizeImg &&
		generateResizeLink(thumbnailUri, ogImageWidthSquare, ogImageHeightSquare, crop);
	const ogImageUrlSquare = omgImageSrcObjSquare ? omgImageSrcObjSquare.src : eLogoImg;

	const twitterImageSrcObj =
		resizeImg && generateResizeLink(thumbnailUri, twitterImgW, twitterImgH, crop);
	const twitterImageUrl = twitterImageSrcObj ? twitterImageSrcObj.src : eLogoImg;

	const optionalTags = { ...defaultOptions, ...options };

	// meta tags with name attribute
	// <meta name="twitter:card" ...
	const baseNamedMetaTags = {
		description: noTagsDescription
	};

	const twitterMetaTags = optionalTags?.twitter
		? {
				'twitter:card': 'summary_large_image',
				'twitter:site': 'https://twitter.com/eonline',
				'twitter:title': titleWithSuffix,
				'twitter:description': noTagsDescription,
				'twitter:image': twitterImageUrl,
				'twitter:url': fullUrl
		  }
		: {};

	const sailthruMetaTags = optionalTags?.sailthru
		? {
				'sailthru.contenttype': 'news',
				'sailthru.cid': id,
				'sailthru.ed.cid': `${edition}_${id}`,
				'sailthru.edition': edition,
				'sailthru.image.full': ogImageUrlSquare,
				'sailthru.date': format(new Date(publishDate), 'yyyy MM dd HH:mm:ss'),
				'sailthru.title': noTagsTitle,
				'sailthru.tags': formatCategories(categories)
		  }
		: {};

	// conditionally add optional tag to name type meta tags
	const nameTypeMetaTags = {
		...baseNamedMetaTags,
		...twitterMetaTags,
		...sailthruMetaTags,
		...(customNameTags && customNameTags)
	};

	// meta tags with property attribute
	// <meta property="og:title" ...
	const ogMetaTags = {
		'og:title': titleWithSuffix,
		'og:type': ogType || 'article',
		'og:url': fullUrl,
		'og:site_name': 'E! Online',
		'og:description': noTagsDescription,
		'og:locale': locale
	};

	const ogImage = {
		'og:image': ogImageUrl,
		'og:image:width': ogImageWidth,
		'og:image:height': ogImageHeight
	};

	const ogImageSquare = {
		'og:image': ogImageUrlSquare,
		'og:image:width': ogImageWidthSquare,
		'og:image:height': ogImageHeightSquare
	};

	// conditionally add optional tags to property type meta tags
	const propertyTypeMetaTags = {
		...(optionalTags?.ogTags && ogMetaTags),
		...(optionalTags?.ogImage && (OG_IMAGE ? ogImage : ogImageSquare)),
		...(customPropTags && customPropTags)
	};

	return (
		<Helmet>
			{Object.keys(propertyTypeMetaTags).map((prop, index) => {
				return <meta key={index} property={prop} content={propertyTypeMetaTags[prop]} />;
			})}

			{Object.keys(nameTypeMetaTags).map((prop, index) => {
				return <meta key={index} name={prop} content={nameTypeMetaTags[prop]} />;
			})}
		</Helmet>
	);
};

MetaTags.propTypes = {
	id: string,
	title: string,
	publishDate: string,
	categories: array,
	ogType: string,
	description: string.isRequired,
	thumbnailUri: string,
	customNameTags: object,
	customPropTags: object,
	options: object
};

MetaTags.displayName = 'MetaTags';

export default MetaTags;
