import React from 'react';
import WidgetHeader from '@/components/widgets/WidgetHeader';
import phoneImg from '@/images/newsletter-phone.jpg';
import enewsLogo from '@/images/newsletter-enews.png';
import qrcode from '@/images/newsletter-app-install-qr-code.png';
import './Phone.scss';

/**
 * @function Phone
 * @description Phone seciton of the newsletter page
 * @description Allows user to download enews app
 * @returns {React.ReactElement}
 */
const Phone = () => {
	return (
		<section className="phone newsletter__section">
			<div className="container">
				<WidgetHeader title="E! on your phone" />
				<div className="columns">
					<div className="column is-half-desktop is-full-tablet phone__graphic">
						<img alt="e on your phone" src={phoneImg} />
					</div>
					<div className="column is-half-desktop is-full-tablet phone__text">
						<img alt="enews" src={enewsLogo} className="phone__e-news-logo" />
						<h4>For iOS and Android</h4>
						<p className="is-hidden-touch">
							Text ENEWS to 44144 to download the E! News App or scan the QR Code below
						</p>
						<img
							alt="QR Code"
							src={qrcode}
							className="phone__qr-code is-hidden-touch"
						/>
						<a
							rel="noopener noreferrer"
							target="_blank"
							href="https://eonline.onelink.me/ItsS/93ee9463"
							className="is-hidden-desktop button"
						>
							download
						</a>
					</div>
				</div>
			</div>
		</section>
	);
};

Phone.displayName = 'Phone';

export default Phone;
